<template>
	<div v-loading="fullscreenLoading">
		<div class="pay">
			<div class="pay-icon" v-if="payInfo.pay_status == '2'">
				<i class="ns-text-color el-icon-circle-check"></i>
			</div>
			<div class="pay-icon" v-if="payInfo.pay_status == '0' && success == 'false'">
				<i class="ns-text-color el-icon-circle-close"></i>
			</div>
			<div class="pay-icon" v-if="payInfo.pay_status == '0' && success == 'true'">
				<i class="ns-text-color el-icon-warning-outline"></i>
			</div>
			<div class="pay-text" v-if="payInfo.pay_status == '2'">Payment Success</div>
			<div class="pay-text" v-if="payInfo.pay_status == '0' && success == 'false'">
				Payment Failed

			</div>
			<div class="pay-text" v-if="payInfo.pay_status == '0' && success == 'true'">
				Payment Pending
			</div>
			<div class="pay-money">Payment amount：PH{{ payInfo.pay_money }}</div>
			<div class="pay-footer">
				<router-link to="/member/index"><el-button type="primary">Account</el-button></router-link>
				<router-link to="/index" class="pay-button"><el-button>back to home page</el-button></router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { getPayInfo } from '@/api/pay';

export default {
	name: 'pay_result',
	components: {},
	data: () => {
		return {
			payInfo: {},
			outTradeNo: '',
			fullscreenLoading: true,
			success: '',
		};
	},
	created() {
		if (!this.$route.query.out_trade_no) {
			this.$router.push({ path: '/' });
			return;
		}
		this.outTradeNo = this.$route.query.out_trade_no;
		this.success = this.$route.query.success;
		this.getPayInfo();
	},
	methods: {
		getPayInfo() {
			getPayInfo({ out_trade_no: this.outTradeNo, forceLogin: true })
				.then(res => {
					const { code, message, data } = res;
					if (code >= 0 && data) {
						console.log(data);
						this.payInfo = data;
						// this.$set(this.payInfo, 'pay_status', data.pay_status)
					} else {
						this.$message({
							message: 'Payment failed',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								// this.$router.push({ path: '/member/order_list' });// 返回订单列表页
							}
						});
					}
					this.fullscreenLoading = false;
				})
				.catch(err => {
					this.fullscreenLoading = false;
					this.$message.error({
						message: "Payment failed",
						duration: 2000,
						onClose: () => {
							// this.$router.push({ path: '/member/order_list' });// 返回订单列表页
						}
					});
				});
		},
	}
};
</script>
<style lang="scss" scoped>
.pay {
	padding: 40px 15px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;

	.pay-icon {
		text-align: center;

		i {
			font-size: 65px;
		}
	}

	.pay-text {
		text-align: center;
		font-size: 16px;
		margin-top: 10px;
	}

	.pay-money {
		text-align: center;
		color: $base-color;
		font-size: $ns-font-size-lg;
	}

	.pay-footer {
		text-align: center;
		margin-top: 30px;

		.pay-button {
			margin-left: 15px;
		}
	}
}
</style>
